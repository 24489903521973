* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Arial', 'sans-serif';
}

html,
body {
  margin: 0;
  padding: 0;
}
